import React from "react";
import Navbar from "./components/Navbar";
import Form from "./components/Form";

function SellPage() {
  return (
    <>
      <Navbar/>
      <Form/>
    </>
  );
}

export default SellPage;
